.footer-holder {
  display: inline-block;

  width: 100%;

  background: #141414;
}

.footer-logo {
  display: inline-block;

  width: 400px;
  height: 260px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 235px 54px;
  background-image: url("../../public/images/logo.svg");

  border-right: 1px solid #3d3d3d;

  vertical-align: top;
}

.footer-items-holder {
  display: inline-block;

  width: calc(100% - 400px);

  padding: 60px 40px;

  vertical-align: top;
}

.footer-item-holder-one {
  display: inline-block;

  width: 31%;

  text-align: left;

  vertical-align: top;
}

.footer-item-holder-two {
  display: inline-block;

  width: 31%;

  text-align: left;

  vertical-align: top;
}

.footer-item-holder-three {
  display: inline-block;

  width: 37%;

  text-align: left;

  vertical-align: top;
}

.footer-parts-title {
  width: 100%;

  margin-bottom: 20px;

  color: #00eb81;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

.footer-parts-title-donation {
  width: 100%;

  margin-bottom: 10px;

  color: #00eb81;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

.footer-item {
  width: 100%;

  margin-bottom: 15px;

  color: #868686;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  text-align: left;
}

.icon-social-holder {
  display: inline-block;

  width: 100%;

  text-align: left;

  margin-bottom: 20px;
}

.icon-donation-holder {
  display: inline-block;

  width: 100%;

  text-align: left;
}

.social-icon {
  display: inline-block;

  width: 40px;
  height: 40px;

  margin-right: 20px;

  background-color: #333333;
  background-position: center;
  background-repeat: no-repeat;

  border-radius: 50%;

  cursor: pointer;
}

.twitter {
  background-image: url("../../public/images/twitter.svg");
}

.twitter:hover {
  background-image: url("../../public/images/twitter-active.svg");
}

.github {
  background-image: url("../../public/images/github.svg");
}

.github:hover {
  background-image: url("../../public/images/github-active.svg");
}

.discord {
  background-image: url("../../public/images/discord.svg");
}

.discord:hover {
  background-image: url("../../public/images/discord-active.svg");
}

.telegram {
  background-image: url("../../public/images/telefram.svg");
}

.telegram:hover {
  background-image: url("../../public/images/telefram-active.svg");
}

.instagram {
  background-image: url("../../public/images/instagram.svg");
}

.instagram:hover {
  background-image: url("../../public/images/instagram-active.svg");
}

.donation-address {
  display: inline-block;
  width: 100%;

  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  word-break: break-all;
}

@media (max-width: 1200px) {
  .footer-logo,
  .footer-items-holder,
  .footer-item-holder-one,
  .footer-item-holder-two,
  .footer-item-holder-three {
    width: 100%;

    overflow: hidden;
  }

  .footer-items-holder {
    padding: 40px 20px;
  }
}
