.event-filter-holder {
  display: inline-block;

  width: 100%;

  padding: 13px 30px;
  margin-bottom: 30px;

  background: #3d3d3d;
  border-radius: 12px;
}

.filters-icon-holder {
  display: inline-block;

  width: 100px;

  text-align: left;

  vertical-align: middle;
}

.filter-icon {
  display: inline-block;

  width: 26px;
  height: 27px;

  margin-right: 10px;

  background-size: 26px 27px;
  background-image: url("../../public/images/filter-icon.svg");
  background-position: center;
  background-repeat: no-repeat;

  vertical-align: middle;
}

.filters-title {
  display: inline-block;

  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;

  vertical-align: middle;
}

.inputs-filter-holder {
  display: inline-block;

  width: calc(100% - 260px);

  text-align: center;

  vertical-align: middle;
}

.filter-input {
  display: inline-block;

  width: calc(33% - 25px);

  padding: 11px 20px;

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #676767;

  background: #333333;

  border-radius: 12px;
  border: 0px none;

  vertical-align: middle;
}

.middle-input {
  margin: 0px 20px;
}

.filter-button {
  display: inline-block;

  width: 160px;

  padding: 10px 5px;

  background: #333333;

  text-align: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  border: 1px solid #00eb81;
  border-radius: 12px;

  vertical-align: middle;
  cursor: pointer;
}
