.menu-holder {
  display: inline-block;

  width: 100%;

  margin-bottom: 20px;

  text-align: left;
}

.menu-item {
  display: inline-block;

  margin-right: 20px;
  padding: 15px 20px;

  background: #333333;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  border-radius: 12px;

  vertical-align: middle;
  cursor: pointer;
}

.menu-item.active {
  color: #00eb81;

  border: 1px solid #00eb81;
}

.arrow-buttons-holder {
  position: absolute;
  top: -2px;
  right: 0px;
}
